import { graphql } from "gatsby"
import Blog from "../components/Posts"

export default Blog

// Query only for specific category on landing page
export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWpPost(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: date }
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Tutorials" } } } }
      }
    ) {
      nodes {
        ...PostTemplateFragment_starter
      }
    }
  }
`